import { useRouter } from "next/router";
import qs from "qs";
import { useCallback } from "react";

import { config } from "~/data";
import { routes } from "~/lib/router";
import { shouldRedirectBaseDynamicSectionLink } from "~/lib/router/helpers";

import { RouteType, useRouteDetector } from "./route-detector";

type RouteOptions = {
  applicationId?: string;
  entityId?: string;
  entityUuid?: string;
  query?: Record<string, string>;
  type?: string;
};

export const useRouteHandler = () => {
  const { getRouteTypeByRouteEntity, getUrlAliasByUrl, isMigratedRoute } = useRouteDetector();
  const router = useRouter();

  const buildUrlWithQuery = (url: string, query: RouteOptions["query"]) => {
    if (!qs.stringify(query)) return url;
    return `${url}?${qs.stringify(query)}`;
  };

  return useCallback(
    (url: string, { applicationId, entityId, query, type }: RouteOptions) => {
      const urlAlias = getUrlAliasByUrl(url);
      const handleOpenPage = (target = "_blank") => {
        window.open(urlAlias, target);
      };
      if (!isMigratedRoute(url)) {
        // If it's not been migrated, use location.href rather than router.push to force it redirect
        location.href = buildUrlWithQuery(url, query);
        return;
      } else if ([config.account.scmpAccountHost].some(host => url.includes(host)))
        return handleOpenPage("_self"); // Special handling for internal hosts

      const routeType = getRouteTypeByRouteEntity({
        applicationId,
        routeType: type,
        urlAlias,
      });
      switch (routeType) {
        case RouteType.Dynamic: {
          const matched = routes.dynamics[type as string];
          if (!matched || !entityId) return;
          const matchedUrl = matched.route(entityId);

          if (shouldRedirectBaseDynamicSectionLink(matchedUrl.pathname, query)) {
            location.href = buildUrlWithQuery(url, query);
            return;
          }

          return router.push(matchedUrl, {
            pathname: urlAlias,
            query,
          });
        }
        case RouteType.Static: {
          // Not direct use url because need to support search route (/search/:value?)
          // Exact match the url first, if not found, try to find the url by prefix
          const key =
            Object.keys(routes.statics).find(staticRoute => staticRoute === url) ??
            Object.keys(routes.statics).find(staticRoute => staticRoute.startsWith(url));
          if (!key) return;
          const matched = routes.statics[key];
          if (!matched) return;
          const matchedUrl = matched({ params: {}, query: query ?? {} });
          return router.push(
            matchedUrl,
            matchedUrl.asPath ?? {
              pathname: urlAlias,
              query,
            },
          );
        }
        default:
          return handleOpenPage();
      }
    },
    [getRouteTypeByRouteEntity, getUrlAliasByUrl, isMigratedRoute, router],
  );
};
